import $ from "jquery";
import { getUrlParams, insertLink } from "GlobalSite";

const types = {
  moteur: { called: false, iname: "aGVsbG9iYW5rX21vdGV1cg==" },
  produit: { called: false, iname: "aGVsbG9iYW5rX3Byb2R1Y3Q=" },
  faq: { called: false },
  messagerie: { called: false, iname: "aGVsbG9iYW5rX21lc3NhZ2VyaWU=" },
};
const pathurljs = "/rsc/contrib/script/aem/inbenta-hb/";
const pathurlcss = "/rsc/contrib/script/aem/inbenta-hb/css/";

const reassignType = (type) => {
  if (type !== "moteur" && type !== "messagerie") {
    return "produit";
  }
  return type;
};
function init(type) {
  // messagerie https://i-contacts.hellobank.fr/messagerie_staging/assets/js/inbenta.js?1526395139129
  // En prod et pré-prod utiliser les scripts locaux
  const bust = "[[BUST]]";

  if (types[reassignType(type)].called) return;
  const data = { dev: false, iname: types[reassignType(type)].iname };
  types[reassignType(type)].called = true;
  let url_script;
  if (ENVIRONNEMENT === "QUALIF" || getUrlParams("mode") === "forceInbenta") {
    // en qualif, local et si mode=forceInbenta utiliser les derniers d'inbenta chez eux
    data.baseURL = `https://f01.inbenta.com/hellobank_${reassignType(type)}_staging/`;
    const assets = "assets/";
    url_script = `${data.baseURL + assets}js/inbenta.js`;
    insertLink(`${data.baseURL + assets}css/inbenta.css?t=${bust}`); // segment to prevent modernizr
  } else {
    // récupération des scripts inbenta chez les serveurs HelloBank!
    data.baseURL =
      ENVIRONNEMENT === "PROD"
        ? `https://f01.inbenta.com/hellobank_${reassignType(type)}/`
        : `https://f01.inbenta.com/hellobank_${reassignType(type)}_staging/`;
    url_script = `${pathurljs}faq/inbenta-${reassignType(type)}.js`;
    insertLink(`${pathurlcss}inbenta-${reassignType(type)}.css?t=${bust}`);
  }
  $.getScript(encodeURI(url_script), () => {
    if (
      reassignType(type) === "moteur" &&
      typeof window.InbentaMoteur !== "undefined"
    ) {
      window.InbentaMoteur.baseURL = data.baseURL;
      mainMoteur(data);
    } else if (
      reassignType(type) === "produit" &&
      typeof window.InbentaProduit !== "undefined"
    ) {
      window.InbentaProduit.baseURL = data.baseURL;
      mainProduit(data);
    } else if (typeof window.Inbenta !== "undefined") {
      window.Inbenta.baseURL = data.baseURL;
      main(data);
    } else {
      return console.error(
        `[Inbenta-FAQ]: Variable globale Inbenta ${reassignType(type)} non initialisée après l'import du script`,
      );
    }
    $(".inbenta.loader").removeClass(`loader-${reassignType(type)}`);
  });
}

export const inbentaFaq = {
  init: init,
};

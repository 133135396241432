import $ from "jquery";
import { DeviceSize as deviceSize, logFactory, isCompExists } from "GlobalSite";
import { inbentaFaq as inbenta_faq } from "./includes/inbentaFaq.mjs";
import { accessibilite } from "./includes/accessibility/accessibilite.mjs";

const compName = "comp_aem_layout_header";
const Log = logFactory(compName);
const { log } = Log;

log("--->");

$(function domReady() {
  Log.log("init header-non-connecté ...");

  if (!isCompExists(compName, Log)) {
    Log.log(`${compName} inexistant ... js associé non exécuté`);

    return;
  }

  const btnSelector = "*[data-popup-target], .btn-nav";
  const btnOpenSelector = '*[data-popup-target][aria-expanded="true"]';
  const popupSelector = ".main-nav__popup";
  const popupOpenSelector = ".main-nav__popup.main-nav__popup__open";
  const $body = $("body");
  const $globalContainer = retrieveStickyContainer();
  const $globalNav = $(".global_header_not_connected");
  const $preNav = $globalNav.find(".pre-nav-container");
  const $nav = $globalNav.find(".main-header-nav");
  const $btnsContainer = $globalNav.find(".main-nav__main-links");
  const $popupContainer = $globalNav.find(".main-nav");
  const $navBtns = $nav.find(btnSelector);
  const $popups = $nav.find(popupSelector);
  const $burger = $nav.find(".burger-menu");
  const $backBtn = $nav.find(".main-nav__btn-retour button");
  const $searchform = $(".search-form");
  const $searchMenu = $("#navbar-search-menu");

  const closeDelayTime = 200;
  let closeDelay = null;
  const screenChangeEventCleaners = [];
  const popupEventCleaners = [];
  const burgerEventCleaners = [];
  let currentScreen = null;
  let $prevFromBtn = null;
  let opened = null;
  const headerAccessibility =
    accessibilite.headerNotConnected.createHeaderAccessibility(
      $popups,
      $navBtns,
      function getAdditionalPopupFocusGroup() {
        return [currentScreen === "mobile" ? $backBtn : null, null];
      },
      function onEscapePopup() {
        if (opened) {
          setPopup(null, null);
          return true;
        }

        if ($globalNav.hasClass("burger-menu-open")) {
          toggleMobileBurger();
          $burger.focus();
          return true;
        }
        return false;
      },
    );

  function setupDesktop() {
    $btnsContainer.on("mouseenter.headerDesktop", btnSelector, (e) => {
      const $btn = getSelectedFromTarget(e.target, btnSelector);
      if (!$btn) {
        return;
      }
      const openType = $btn.attr("data-popup-open-type");
      const target = $btn.attr("data-popup-target");
      let $popup = $popups.filter(target);

      if (!target || openType === "click") {
        if (isOpenPopupClickable()) {
          return;
        }
        $popup = null;
      } else if (isOpenPopupClickType()) {
        return;
      }
      clearTimeout(closeDelay);
      setPopup($popup, $btn);
    });
    $btnsContainer.on("mouseleave.headerDesktop", btnOpenSelector, () => {
      if (isOpenPopupClickable()) {
        return;
      }

      closeDelay = setTimeout(() => {
        closePopup();
      }, closeDelayTime);
    });

    $popupContainer.on("mouseenter.headerDesktop", popupOpenSelector, () => {
      clearTimeout(closeDelay);
    });

    $popupContainer.on("mouseleave.headerDesktop", popupOpenSelector, () => {
      if (isOpenPopupClickType()) {
        return;
      }

      closeDelay = setTimeout(() => {
        closePopup();
      }, closeDelayTime);
    });

    screenChangeEventCleaners.push(() => {
      $btnsContainer.off("mouseenter.headerDesktop");
      $btnsContainer.off("mouseleave.headerDesktop");
      $popupContainer.off("mouseenter.headerDesktop");
      $popupContainer.off("mouseleave.headerDesktop");
    });
  }

  function setupMobile() {
    $burger.on("click.headerMobile", () => {
      toggleMobileBurger();
    });

    $backBtn.on("click.headerMobile", () => {
      setPopup(null, null);
    });

    screenChangeEventCleaners.push(() => {
      $burger.off("click.headerMobile");
      $backBtn.off("click.headerMobile");
    });
  }

  function setupGeneralOnce() {
    headerAccessibility.setupGeneralOnce();

    $btnsContainer.on("click.headerOpen", "*[data-popup-target]", (e) => {
      const $btn = getSelectedFromTarget(e.target, btnSelector);
      if (!$btn) {
        return;
      }

      if (isBtnFromOpenedPopup($btn)) {
        setPopup(null, null);
      } else {
        const $popup = $popups.filter($btn.attr("data-popup-target"));
        setPopup($popup, $btn);
        $btn.attr("data-popup-click-opened", "true");
        if (currentScreen === "mobile") {
          $backBtn.focus();
        }
      }
      e.preventDefault();
    });
  }

  function toggleMobileBurger() {
    $globalNav.toggleClass("burger-menu-open");
    $burger.toggleClass("open");
    $burger.attr("aria-expanded", (_, attr) =>
      attr === "true" ? "false" : "true",
    );
    $body.toggleClass("nav-mobile-open");
    if ($globalNav.hasClass("burger-menu-open")) {
      const $firstElem = $nav.find(".burger-menu-wrapper .logo-nav");
      const $lastElem = $nav.find(".connect-nav__more > a");
      $nav.on("keydown.burgerTrapFocus", (e) => {
        if (
          accessibilite.utils.varsAndConsts.KEYS.isTabShift(e) &&
          $(":focus").is($firstElem)
        ) {
          e.preventDefault();
        } else if (
          accessibilite.utils.varsAndConsts.KEYS.isTab(e) &&
          $(":focus").is($lastElem)
        ) {
          e.preventDefault();
        }
      });
      burgerEventCleaners.push(() => {
        $nav.off("keydown.burgerTrapFocus");
      });
    } else {
      setPopup(null, null);
      runCleaners(burgerEventCleaners);
    }
  }

  function setPopup($popup, $fromBtn) {
    if (opened?.[0] && opened[1]) {
      if (opened[0].is($popup) && opened[1].is($fromBtn)) {
        return;
      }
      closePopup();
    }
    if (!$popup || !$fromBtn) {
      if (
        $prevFromBtn &&
        $(":focus").parents(".main-nav__popup, .main-nav__btn-retour").length
      ) {
        $prevFromBtn.focus();
      }
      $prevFromBtn = null;
      return;
    }
    openPopup($popup, $fromBtn);
    $prevFromBtn = $fromBtn;
  }
  function openPopup($popup, $fromBtn) {
    clearTimeout(closeDelay);
    $popup.addClass("main-nav__popup__open");
    $fromBtn.attr("aria-expanded", true);
    opened = [$popup, $fromBtn];
    $globalNav.addClass("popup-open");
    if ($popup.attr("id") !== "popupHeaderNavSearchBar") {
      popupEventCleaners.push(
        headerAccessibility.setupPopupOpens($popup, $fromBtn, currentScreen),
      );
    } else {
      setTimeout(fixLegacySearchPopup);
    }
    function fixLegacySearchPopup() {
      $searchMenu.addClass("in");
      $searchform
        .filter(`.search-form--${currentScreen}`)
        .find("input")
        .focus()
        .val("")
        .trigger("keyup");
      popupEventCleaners.push(
        headerAccessibility.setupPopupOpens($popup, $fromBtn, currentScreen),
      );
    }
  }

  function closePopup() {
    if (!opened || !opened[0] || !opened[1]) {
      return;
    }
    const $popup = opened[0];
    const $fromBtn = opened[1];
    $popup.removeClass("main-nav__popup__open");
    if ($popup.attr("id") === "popupHeaderNavSearchBar") {
      $searchMenu.removeClass("in");
    }
    $fromBtn.attr("aria-expanded", false);
    $fromBtn.attr("data-popup-click-opened", false);
    opened = null;
    $globalNav.removeClass("popup-open");
    runCleaners(popupEventCleaners);
  }

  function isBtnFromOpenedPopup($btn) {
    return opened?.[1].is($btn);
  }

  function isOpenPopupClickable() {
    return (
      opened &&
      (opened[1].attr("data-popup-open-type") === "click" ||
        opened[1].attr("data-popup-click-opened") === "true")
    );
  }
  function isOpenPopupClickType() {
    return opened && opened[1].attr("data-popup-open-type") === "click";
  }

  function getSelectedFromTarget(target, selector) {
    const $elem = $(target);
    if ($elem.is(selector)) {
      return $elem;
    }

    const parents = $elem.parents();
    for (const parent of parents) {
      const $p = $(parent);
      if ($p.is(selector)) {
        return $p;
      }
    }
    return null;
  }

  function runCleaners(eventCleaners) {
    while (eventCleaners.length > 0) {
      const ec = eventCleaners.pop();
      ec();
    }
  }

  function retrieveStickyContainer() {
    let $container = $(`._${compName}`);
    const parents = $container.parents().toArray();
    const parentContainer = parents.find((p) => $(p).is(".experiencefragment"));
    if (parentContainer) {
      $container = $(parentContainer);
    }
    Log.log("Generated sticky container:", $container);
    $container.addClass("header-sticky");
    return $container;
  }

  function resetState() {
    $globalNav.removeClass("burger-menu-open");
    $globalNav.removeClass("popup-open");
    $burger.removeClass("open");
    $body.removeClass("nav-mobile-open");
    setPopup(null, null);

    runCleaners(screenChangeEventCleaners);
    $prevFromBtn = null;
  }

  function setupNavOver() {
    const observer = new IntersectionObserver(
      ([e]) => {
        if ($globalNav.hasClass("burger-menu-open")) {
          return;
        }
        if (e.isIntersecting) {
          $nav.removeClass("main-header-nav--over");
        } else {
          $nav.addClass("main-header-nav--over");
        }
      },
      { rootMargin: "-1px 0px 0px 0px" },
    );

    observer.observe($preNav.get(0));
  }

  function screenResize() {
    const width = $(window).outerWidth();
    if (width < 1024 && currentScreen !== "mobile") {
      currentScreen = "mobile";
      resetState();
      setupMobile();
    } else if (width >= 1024 && currentScreen !== "desktop") {
      currentScreen = "desktop";
      resetState();
      setupDesktop();
    }
    const realWidth =
      $body.outerWidth() +
      Number.parseInt($body.css("margin-left")) +
      Number.parseInt($body.css("margin-right"));
    $globalContainer
      .get(0)
      .style.setProperty("--scrollbar-width", `calc(100vw - ${realWidth}px)`);
    const height = $globalNav.outerHeight() - $nav.outerHeight();
    $globalContainer
      .get(0)
      .style.setProperty("--pre-nav-height", `-${height + 1}px`);
  }

  $(window).resize(() => {
    screenResize();
  });

  setupGeneralOnce();
  screenResize();
  setupNavOver();

  function setupSearch() {
    $(".btn-search-close").on("click", () => {
      setPopup(null, null);
    });

    function initSearchEngine() {
      inbenta_faq.init("moteur");
      const inbScriptJS = document.createElement("script");
      const path =
        location.host.indexOf("localhost") !== -1
          ? "/rsc/contrib/script/aem/mInbentaHb"
          : "/rsc/contrib/script/aem/mInbentaHb";
      inbScriptJS.src = `${path}/conf/inbenta-conf-recette.js`;
      document.head.appendChild(inbScriptJS);
    }
    initSearchEngine(); // FIX ME -> not working on localhost

    $searchform.find("input").on("keyup", function (e) {
      $searchform.find("input").val($(this).val());
      if ($(this).val().length) {
        $(this).siblings(".search-label").addClass("no-width");
      } else {
        $(this).siblings(".search-label").removeClass("no-width");
      }

      if (e.keyCode === 13) {
        e.preventDefault();
        const form = $(this).parent();
        submit_search(form);
      }
    });

    $searchform.on("submit", function (e) {
      e.preventDefault();
      submit_search($(this));
    });

    function submit_search(form) {
      if (typeof $(form).getFormData().question !== "undefined") {
        $(form).find("input").blur();
      }
      if (deviceSize.getDeviceState() !== "mobile") {
        $(".burger-menu.btn-search-close").toggleClass("hide");
      }
    }
  }

  setupSearch();
});

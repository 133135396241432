import $ from "jquery";
import { varsAndConsts } from "./vars-and-consts.mjs";
//================== header functions area ======================
//==== functions that express a logic specific to the header ====
//===============================================================
/**
 * If the shift key is not pressed and the key code is tab, return true.
 * @param e - the event object
 * @returns A boolean value.
 */
function tabCondition(e) {
  return !e.shiftKey && e.keyCode === varsAndConsts.KEY_CODE.tab;
}

/**
 * If the shift key is pressed and the key code is tab, return true.
 * @param e - the event object
 * @returns A boolean value.
 */
function shiftTabCondition(e) {
  return e.shiftKey && e.keyCode === varsAndConsts.KEY_CODE.tab;
}

/**
 * If the keyCode of the event is the same as the escape key's keyCode, return true, otherwise return
 * false.
 * @param e - the event object
 * @returns A boolean value.
 */
function escapeCondition(e) {
  return e.keyCode === varsAndConsts.KEY_CODE.escape;
}

/**
 * It removes the "active" class from the two buttons that toggle the sub-navs, and removes the "in"
 * class from the two sub-navs
 */
function closeAllSubNavs() {
  const $jsToggleOffre = $(".js-toggle-offre");
  const $jsToggleCartes = $(".js-toggle-cartes");
  const $ssNavOffre = $("#ssNavOffre");
  const $ssNavCartes = $("#ssNavCartes");
  $jsToggleCartes.removeClass("active").attr("aria-expanded", false);
  $jsToggleOffre.removeClass("active").attr("aria-expanded", false);
  $ssNavCartes.removeClass("in");
  $ssNavOffre.removeClass("in");
}

export const header = {
  tabCondition: tabCondition,
  shiftTabCondition: shiftTabCondition,
  escapeCondition: escapeCondition,
  closeAllSubNavs: closeAllSubNavs,
};

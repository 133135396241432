import $ from "jquery";
import { varsAndConsts } from "./vars-and-consts.mjs";

//============ Generic functions area ============
//==== functions that express a generic logic ====
//================================================

/**
 * Set events to move with arrow keys between a set of elements.
 * @param $focusGroup - The elements part of the focus group.
 * @param onEndingsEvent - A callback executed when an action is done at the ends.
 * Sends "next" when the next key was pressed.
 * Sends "exitFromEnd" when focus is on the end of the group and the Tab (asking to leave focus) key was pressed.
 * Sends "prev" when the prev key was pressed.
 * Sends "exitFromStart" when focus is on the start of the group and the Shift-Tab (asking to leave focus) key was pressed.
 * Sends "tab" when the tab key was pressed, and focus isn't on the start.
 * Sends "shiftTab" when the shiftTab key was pressed, and focus isn't on the end.
 * @returns A function that cleans the events that were setup.
 */
function setFocusGroupEvents($focusGroup, onEndingsEvent) {
  if (!$focusGroup || !$focusGroup.length) return;
  const $parents = $focusGroup.eq(0).parents();
  let $parent = null;
  for (let i = 0; i < $parents.length; i++) {
    $parent = $parents.eq(i);
    if ($parent.find($focusGroup).length === $focusGroup.length) {
      break;
    }
  }
  $parent.on("keydown.focusGroup", $focusGroup, (event) => {
    const ctx = {
      $elem: $(event.target),
      key: event.key,
      $focusGroup,
      event,
    };
    ctx.idx = ctx.$focusGroup.index(ctx.$elem);
    const acted =
      _handleFocusMove(ctx, onEndingsEvent) ||
      _handleTabs(ctx, onEndingsEvent) ||
      _handleSpecialKeys(ctx, onEndingsEvent);
    acted && event.preventDefault();
  });

  return () => {
    $parent.off("keydown.focusGroup");
  };
}

function _handleFocusMove(ctx, onEndingsEvent) {
  let acted = false;
  if (varsAndConsts.KEYS.nextKeys.includes(ctx.key)) {
    if (ctx.idx !== ctx.$focusGroup.length - 1) {
      ctx.$focusGroup.eq(ctx.idx + 1).focus();
      acted = true;
    }
    acted = onEndingsEvent("next", ctx) || acted;
  } else if (varsAndConsts.KEYS.prevKeys.includes(ctx.key)) {
    if (ctx.idx !== 0) {
      ctx.$focusGroup.eq(ctx.idx - 1).focus();
      acted = true;
    }
    acted = onEndingsEvent("prev", ctx) || acted;
  }
  return acted;
}
function _handleTabs(ctx, onEndingsEvent) {
  let type = null;
  if (varsAndConsts.KEYS.isTab(ctx.event)) {
    type = ctx.idx === ctx.$focusGroup.length - 1 ? "exitFromEnd" : "tab";
  } else if (varsAndConsts.KEYS.isTabShift(ctx.event)) {
    type = ctx.idx === 0 ? "exitFromStart" : "shiftTab";
  }
  return type && onEndingsEvent && onEndingsEvent(type, ctx);
}
function _handleSpecialKeys(ctx, onEndingsEvent) {
  let acted = false;
  if (ctx.key === varsAndConsts.KEYS.home) {
    ctx.$focusGroup.eq(0).focus();
    acted = true;
  } else if (ctx.key === varsAndConsts.KEYS.end) {
    ctx.$focusGroup.eq(-1).focus();
    acted = true;
  }
  return acted;
}

/**
 * If the element has the class 'open' or 'in', return true, otherwise return false.
 * @param  - The element that you want to toggle the attribute on.
 * @returns A boolean value
 */
function toogleAttrAriaExpanded($elem) {
  return $elem.hasClass("open") || $elem.hasClass("in");
}

/**
 * If the key pressed is the space bar or the enter key, and the target of the event is not an anchor
 * tag, then return true
 * @param event - The event object that is passed to the event handler.
 * @returns A boolean value.
 */
function enterOrSpaceCondition(event) {
  return (
    (event.keyCode === varsAndConsts.KEY_CODE.space ||
      event.keyCode === varsAndConsts.KEY_CODE.enter) &&
    !$(event.target).attr("href")
  );
}

/**
 * If the user presses the shift key and the first focusable element is focused, focus the last
 * focusable element. If the user does not press the shift key and the last focusable element is
 * focused, focus the first focusable element
 * @param  - The element that you want to trap focus within.
 * @param e - The event object
 */
function trapFocus($elem, e) {
  const $focusableContent = $elem.find(varsAndConsts.FOCUSABLE_ELEMENTS);
  const $firstFocusableElement = $focusableContent.eq(0);
  const $lastFocusableElement = $focusableContent.eq(-1);

  if (e.shiftKey && $firstFocusableElement.is(":focus")) {
    $lastFocusableElement.focus();
    e.preventDefault();
  }

  if (!e.shiftKey && $lastFocusableElement.is(":focus")) {
    $firstFocusableElement.focus();
    e.preventDefault();
  }
}

//==============================
//====== object to return ======
//==============================
export const generic = {
  setFocusGroupEvents,
  toogleAttrAriaExpanded,
  enterOrSpaceCondition,
  trapFocus,
};

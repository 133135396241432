import $ from "jquery";
import { utils } from "./../utils/accessibilite.utils.mjs";
//===============================onFocusIn Area===================================
//====== onFocusIn functions to better expose the logic behind the behavior ======
//================================================================================

function createHeaderAccessibility(
  $popups,
  $navBtns,
  getAdditionalPopupFocusGroup,
  onEscapePopup,
) {
  const $doc = $(document);
  let $openedFocusGroup = null;

  // list all popup focusable elements
  function _getPopupFocusGroup($popup) {
    const toAdd = getAdditionalPopupFocusGroup($popup);
    const front = toAdd[0] ? toAdd[0].slice().toArray() : [];
    const back = toAdd[1] ? toAdd[1].slice().toArray() : [];
    let $focusables = $popup.find(utils.varsAndConsts.FOCUSABLE_ELEMENTS);
    $focusables = $focusables.filter(":not(:disabled, [tabindex=-1]):visible");
    let finalArr = front;
    finalArr = finalArr.concat($focusables.toArray());
    finalArr = finalArr.concat(back);
    return $(finalArr);
  }

  function setupGeneralOnce() {
    utils.generic.setFocusGroupEvents($navBtns, (type, ctx) =>
      eventActionsOnBtns[type](ctx),
    );

    $doc.on("keydown.headerEscapePopup", (e) => {
      if (e.key === utils.varsAndConsts.KEYS.esc) {
        onEscapePopup() && e.preventDefault();
      }
    });
  }
  const eventActionsOnBtns = {
    tab: (ctx) => eventActionsOnBtns.onFocusInPopup(ctx),
    prev: (ctx) => ctx.idx === 0,
    next: (ctx) => {
      if (
        ctx.key === utils.varsAndConsts.KEYS.down &&
        eventActionsOnBtns.onFocusInPopup(ctx)
      ) {
        return true;
      }
      return ctx.idx === ctx.$focusGroup.length - 1;
    },
    onFocusInPopup: (ctx) => {
      if (
        !ctx.$elem.data("popup-target") ||
        ctx.$elem.attr("aria-expanded") !== "true"
      ) {
        return false;
      }

      $openedFocusGroup.eq(0).focus();
      return true;
    },
  };

  function setupPopupOpens($popup, $btn, screenType) {
    $openedFocusGroup = _getPopupFocusGroup($popup);

    const cleanup = utils.generic.setFocusGroupEvents(
      $openedFocusGroup,
      (type, ctx) => eventActionsInPopup?.[type]($btn, screenType, ctx),
    );

    if (screenType === "desktop") {
      $doc.on("click.headerEscapePopup", (e) => {
        const $target = $(e.target);
        if (
          $popups.is($target) ||
          $popups.find($target).length ||
          $navBtns.is($target) ||
          $navBtns.find($target).length ||
          $target.closest("#inbenta-modal-results").length
        ) {
          return;
        }
        onEscapePopup() && e.preventDefault();
      });
    }

    return () => {
      $openedFocusGroup = null;
      cleanup();
      if (screenType === "desktop") {
        $doc.off("click.headerEscapePopup");
      }
    };
  }
  const eventActionsInPopup = {
    exitFromStart: ($btn) => {
      if ($btn.is(":visible")) {
        $btn.focus();
        return true;
      }
      return false;
    },
    exitFromEnd: ($btn, screenType) => {
      if (screenType === "desktop") {
        const idx = $navBtns.index($btn);
        const nextIdx = (idx + 1) % $navBtns.length;
        const $nextBtn = $navBtns.eq(nextIdx);
        $btn.trigger("click");
        $nextBtn.trigger("focus");
        $nextBtn.trigger("click");
      }
      return true;
    },
    prev: (_, __, ctx) => ctx.idx === 0,
    next: (_, __, ctx) => ctx.idx === ctx.$focusGroup.length - 1,
  };

  return {
    setupGeneralOnce,
    setupPopupOpens,
  };
}

//==============================
//====== object to return ======
//==============================
export const headerNotConnected = {
  createHeaderAccessibility,
};

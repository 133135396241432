//======== const/var declarations ========
const KEY_CODE = {
  enter: 13,
  space: 32,
  tab: 9,
  shift: 16,
  escape: 27,
};

const FOCUSABLE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const KEYS = {
  left: "ArrowLeft",
  right: "ArrowRight",
  up: "ArrowUp",
  down: "ArrowDown",
  enter: "Enter",
  space: "Space",
  home: "Home",
  end: "End",
  esc: "Escape",
};

KEYS.nextKeys = [KEYS.right, KEYS.down];
KEYS.prevKeys = [KEYS.left, KEYS.up];
KEYS.moveInKeys = [KEYS.down, KEYS.enter, KEYS.tab];
KEYS.isTab = function isTab(e) {
  return e.key === "Tab" && !e.shiftKey;
};
KEYS.isTabShift = function isTabShift(e) {
  return e.key === "Tab" && e.shiftKey;
};

export const varsAndConsts = {
  KEYS,
  KEY_CODE: KEY_CODE,
  FOCUSABLE_ELEMENTS: FOCUSABLE_ELEMENTS,
};
